.Background {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(10px);
  z-index: 100;
  overflow: hidden;
}

.BackgroundClosed {
  display: none;
}

.Scrollbar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* background-color: #000000a3; */
  overflow-y: auto;
  z-index: 20;
}

.TaskModalParent {
  width: clamp(500px, 60%, 1000px);
  margin: 20px 0;
  background-color: white;
  border-radius: 30px;
  height: fit-content;
  overflow: hidden;
}

.CheckBox {
  cursor: pointer;
}

.ComponentParent {
  padding: 0 30px 40px;
}




.CheckListTitle {
  width: 100%;
  padding: 5px;
  min-height: 30px;
  border: none;
  outline: none;
  margin: 20px 0 10px;
  font-size: 20px;
  border: 1px solid transparent;
  cursor: pointer;
}

.CheckListTitle:focus {
  border: 1px solid #5b50a4;
}

.CheckList {
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
}

.ProgressBar__Parent {
  margin-bottom: 20px;
}
.ExitIsList {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 10;
  color: #fff;
}

.Checks {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  justify-content: space-between;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-bottom: 10px;
}

.TextAreaElement {
  width: 100%;
  resize: none;
  padding: 5px;
  border: none;
  outline: none;
  overflow-wrap: break-word;
  min-height: 30px;
  background-color: #fff;
  border-radius: 3px;
  height: 30px;
}

.TextAreaElement:focus {
  border: 1px solid #5b50a4;
  height: auto;
}

.CancelSaveParent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  height: 40px;
}

.CancelSaveParent:not(:last-child) {
  margin-bottom: 0;
}

.InputElement {
  width: 100%;
  padding: 5px;
  min-height: 30px;
  border: none;
  outline: none;
  margin-bottom: 10px;
  font-size: 25px;
  border: 1px solid transparent;
  cursor: pointer;
  margin-top: 20px;
}

.InputElement:focus {
  border: 1px solid #5b50a4;
}

.DescriptionTitle {
  color: #212529;
  margin-bottom: 10px;
}

.DescriptionParent {
  width: 100%;
  position: relative;
}

.AddDescription {
  width: 100%;
  min-height: 50px;
  border: none;
  padding: 10px;
  color: black;
  text-align: left;
  /* display: flex; */
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-bottom: 10px;
  list-style-position: inside;
}

.DescriptionButtonsParent {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.TaskModalWrapper {
  display: grid;
  grid-template-columns: 1fr 150px;
}

.RightBar {
  padding: 50px 10px 20px;
  height: fit-content;
  min-height: 300px;
  display: grid;
  gap: 10px;
  align-content: flex-start;
}

.CheckBoxListOpen {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  height: 30px;
}

.Galleries {
  display: grid;
  gap: 10px;
}

.Galleries__items {
  width: 150px;
  height: 100px;
  cursor: pointer;
}

.Galleries__image {
  width: 100%;
  height: 100%;
}
