.InputParent {
  position: relative;
  width: 100%;
}

.Input {
  width: 100%;
}
.ShowPassword {
  position: absolute;
  right: 10px;
  top: 28px;
  transform: translateY(-50%);
}

.ErrorMessage {
  color: red;
  height: 20px;
  text-align: left;
}
