.Project {
  display: flex;
  gap: 30px;
  overflow-x: scroll;
  padding: 0 6px;
  position: relative;
  height: 100%;
  background-color: #f1f2f7;
}

.BoardTitleParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.RightSideIcons {
  display: flex;
  align-items: center;
}

.Tasks {
  padding: 10px 8px;
  border: 0.5px solid #495584;
  margin-bottom: 10px;
  background-color: #ffffff;
}

.Title {
  color: #495584;
  font-size: 15px;
}

.Line {
  width: 100%;
  height: 2px;
  background: #d9d9d9;
  margin: 18px 0;
}

.SubTitle {
  color: #495584;
  font-size: 8px;
}

.InputParent {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.BoardInput {
  border: none;
  width: 100%;
  height: 30px;
  box-shadow: 0 0 5px black;
  border-radius: 10px;
  padding: 0 10px;
}

.TaskName {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Images {
  width: 100%;
  height: 150px;
}

.Completes {
  color: #e00000;
  font-size: 8px;
  margin-top: 11px;
  margin-bottom: 15px;
}

.CreatedUsersParent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 13px;
  margin-top: 30px;
}

.CreatedUsers {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.UserNameFirstLatterOrImage {
  width: 30px;
  height: 30px;
  border: 0.5px solid #0e9fb3;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: #0e9fb3;
  font-size: 12px;
  overflow: hidden;
}

.Avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.UserNameAndDeveloperType {
  color: #495584;
  font-size: 8px;
}

.WorkTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.StartAndDeadline {
  color: #495584;
  font-size: 8px;
}

.DroppableDiv {
  overflow-y: scroll;
  height: 90%;
}
