.EditProject {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 30px;
  padding: 0 6px;
  position: relative;
}

.PageTitleParent {
  width: 100%;
  height: 33px;
  border-radius: 2px;
  border: 0.5px solid #eaedf1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-right: 9px; */
}

.Form {
  height: 100%;
}

.ScrollBox {
  height: calc(100% - 50px);
  overflow-y: scroll;
}

.PageTitle {
  color: #495584;
  font-size: 12px;
  width: 100%;
  height: 100%;
  padding-left: 9px;
  border: none;
  outline: none;
}

.StartAndDeadlineMainParent {
  display: flex;
  margin-top: 28px;
  align-items: center;
  width: 100%;
  padding: 0 9px;
  gap: 10px;
}

.WorkingTimeParent {
  width: 130px;
  border-bottom: 1px solid #eaedf1;
  padding-bottom: 7px;
}

.WorkingTimeTitle {
  color: #495584;
  font-size: 8px;
}

.StartAndDeadlineDate {
  color: #212529;
  font-size: 12px;
}

.DescriptionTitle {
  padding: 0 9px;
  margin-top: 22px;
  color: #212529;
  font-size: 12px;
}

.DescriptionParent {
  width: 100%;
  position: relative;
  padding: 20px;
}

.Description {
  width: 100%;
  color: #495584;
  font-size: 12px;
  margin-top: 11px;
  padding: 9px;
  border: none;
  resize: vertical;
  min-height: 50px;
}

.ProjectLogoTitle {
  padding: 0 9px;
  margin-top: 22px;
  color: #212529;
  font-size: 12px;
}

.ProjectLogo {
  width: 54px;
  height: 54px;
  border-radius: 2px;
  margin-top: 13px;
  margin-left: 9px;
}

.ProjectTZTitle {
  color: #212529;
  font-size: 12px;
  padding: 0 9px;
  margin-top: 22px;
}

.ProjectTZ {
  color: #495584;
  font-size: 12px;
  margin-left: 9px;
  margin-top: 5px;
}

.UsersTitle {
  color: #212529;
  font-size: 12px;
  padding: 0 9px;
  margin-top: 22px;
}

.CreatedUsers {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 9px;
  margin-left: 9px;
}

.UserNameFirstLatterOrImage {
  width: 30px;
  height: 30px;
  border: 0.5px solid #0e9fb3;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: #0e9fb3;
  overflow: hidden;
}

.Avatar {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.UserNameAndDeveloperType {
  color: #495584;
  font-size: 8px;
}

.ButtonsParent {
  position: absolute;
  bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 30px);
}

.ErrorMessage {
  color: red;
  margin: 5px 0 20px;
  font: 12px sans-serif;
}
