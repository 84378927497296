.Background {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(10px);
  z-index: 100;
}

.BackgroundClosed {
  display: none;
}

.UserListParent {
  position: absolute;
  inset: 20%;
  background-color: white;
  border-radius: 30px;
  padding: 40px 30px;
}

.UserList {
  /* border: 1px solid #000; */
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 10px 20px;
}

.ExitIsList {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.Users {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  justify-content: space-between;
}

.UserNameFirstLatterOrImage {
  width: 30px;
  height: 30px;
  border: 0.5px solid #0e9fb3;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: #0e9fb3;
  overflow: hidden;
}

.Avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.UserNameAndDeveloperType {
  color: #495584;
  font-size: 8px;
  position: relative;
}

#UserName::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #05de4f;
  right: -10px;
  top: 0;
}

.LeftSide {
  display: flex;
  align-items: center;
  gap: 10px;
}
