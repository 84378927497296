@font-face {
  font-family: "Regular";
  src: url("./Assets/fonts/Roboto-Regular.ttf");
}

body {
  font-family:
    "Regular",
    -apple-system,
    BlinkMacSystemFont,
    "Helvetica Neue",
    "Segoe UI",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Open Sans",
    sans-serif;
}

*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
}

a {
  color: #000;
}

ul {
  list-style: none;
}

.tox-statusbar__branding {
  display: none !important;
}
