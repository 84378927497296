.Staff {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 6px;
  gap: 30px;
}

.ProjectsDevelopmentParent {
  width: 250px;
  padding: 10px 8px;
  /* border: 0.5px solid #495584; */
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* .Title {
  font-size: 15px;
  color: #495584;
}

.Line {
  width: 100%;
  height: 2px;
  margin: 18px 0;
  background: #d9d9d9;
} */

.CreatedUsers {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  position: relative;
}

.CreatedUsers::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 4px;
  height: 4px;
  content: "";
  border-radius: 50%;
  background: #05de4f;
}

.UserNameFirstLatterOrImage {
  display: grid;
  min-width: 40px;
  width: 40px;
  height: 40px;
  color: #0e9fb3;
  border: 0.5px solid #0e9fb3;
  border-radius: 50%;
  place-items: center;
  overflow: hidden;
}

.UserName {
  font-size: 18px;
  color: #495584;
  white-space: pre;
}

.DeveloperType {
  font-size: 14px;
  color: #495584;
  white-space: pre;
}

#UserName {
  text-transform: capitalize;
}

.Avatar {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.ProjectsDevelopmentTitle {
  font-size: 12px;
  margin-top: 18px;
  color: #495584;
}

.ProjectsDevelopmentUL {
  font-size: 15px;
  display: grid;
  margin-top: 6px;
  list-style: disc;
  list-style-position: inside;
  color: #212529;
  gap: 6px;
}
