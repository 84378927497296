.AddProjects {
  height: 100%;
  display: flex;
  gap: 30px;
  /*overflow-x: scroll;*/
  padding: 0 6px;
  position: relative;
}

.ScrollableBox {
  overflow-y: scroll;
  height: 100%;
}

.StartAndDeadlineParent {
  display: flex;
  gap: 16px;
}

.WorkingTimeParent {
  width: 120px;
  /* border-bottom: 1px solid #eaedf1; */
  /* padding-bottom: 7px; */
}

.WorkingTimeTitle {
  color: #495584;
  font-size: 8px;
}

.StartAndDeadlineDate {
  color: #212529;
  font-size: 12px;
  /* border: none;
  outline: none; */
  padding: 0;
}

.Line {
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  margin-top: 8px;
  margin-bottom: 12px;
}

.ProjectTitle {
  width: 100%;
  height: 33px;
  padding: 7px;
  border-radius: 2px;
  border: 0.5px solid #495584;
  color: #495584;
  font-size: 12px;
  resize: none;
  outline: none;
}

.ProjectDescription {
  width: 100%;
  height: 230px;
  padding: 7px;
  border-radius: 2px;
  border: 0.5px solid #495584;
  color: #495584;
  font-size: 12px;
  resize: none;
  outline: none;
}

.ButtonsParent {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.ErrorMessage {
  color: red;
  margin: 5px 0 20px;
  font: 12px sans-serif;
}

.UsersTitle {
  color: #212529;
  font-size: 12px;
  padding: 0 9px;
  margin-top: 22px;
}

.CreatedUsers {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 9px;
  margin-left: 9px;
}

.UserNameFirstLatterOrImage {
  width: 24px;
  height: 24px;
  border: 0.5px solid #0e9fb3;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: #0e9fb3;
  font-size: 12px;
  overflow: hidden;
}

.Avatar {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.UserNameAndDeveloperType {
  color: #495584;
  font-size: 8px;
}
