.Projects {
  height: 100%;
  display: flex;
  gap: 30px;
  overflow-x: scroll;
  padding: 0 6px;
  position: relative;
}

.TitleParent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Title {
  color: #3a3a3a;
  font-size: 20px;
}

.EditIcon {
  cursor: pointer;
}

.Status {
  color: #e00000;
  font-size: 8px;
  margin-bottom: 5px;
}

.TaskInfo {
  color: #212529;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -moz-box;
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.StartEndTimeWork {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 21px;
}

.StartEnd {
  color: #495584;
  font-size: 8px;
}

.Progress {
  appearance: none;
  width: 100%;
  height: 2px;
}

.Progress::-webkit-progress-bar {
  background: #d9d9d9;
}

.Progress::-webkit-progress-value {
  background: #e00000;
}

.TasksParent {
  margin-top: 14px;
  overflow: auto;
}

.ShowAllSprints {
  float: right;
  color: #495584;
  font-size: 10px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 10px;
}

.CreatedUsersParent {
  display: flex;
  row-gap: 5px;
  flex-direction: column;
  margin-top: 10px;
}

.CreatedUsers {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.UserNameFirstLatterOrImage {
  width: 30px;
  height: 30px;
  border: 0.5px solid #0e9fb3;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: #0e9fb3;
  font-size: 12px;
  overflow: hidden;
}

.UserNameAndDeveloperType {
  color: #495584;
  font-size: 8px;
}
