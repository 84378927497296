.FormParent {
  width: 500px;
  height: 500px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.167);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 40px;
  padding: 0 50px;
}

.PaddingBox {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  left: 0;
  height: 370px;
}

.SwipeBar {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  transition: left 0.5s linear;
}

.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px !important;
  height: 100%;
  flex-shrink: 0 !important;
  overflow-y: scroll;
  padding: 10px 0;
}

.TitleParent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto 40px;
}

.Title {
  color: rgb(0, 0, 0);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px black;
  background-clip: text;
  font-size: 70px;
  text-align: center;
  width: calc(100% - 24px);
}

.ErrorMessage {
  color: red;
  margin-top: 20px;
}

.FileInputParent {
  width: 100%;
}

.NextParent {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
