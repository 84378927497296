@import "../Variables.css";

.RobotEset {
  width: 40px;
  height: 45px;
}

.SideBarHeader {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
  box-shadow: 0 0 12px var(--box-shadow-color);
  border-right: 0.5px solid var(--border-color);
}

.SideBarUl {
  padding: 20px 0 20px 10px;
  width: 100%;
}

.Aside {
  background-color: var(--main-background-color);
  box-shadow: 7px 4px 12px 0 var(--box-shadow-color);
  height: 100vh;
  overflow: hidden;
}

.SideBarList {
  color: var(--text-color);
  font-size: 14px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  position: relative;
  border-bottom: 0.5px dashed rgba(75, 88, 133, 0.15);
}

.SideBarList.active::before,
.SideBarList.active::after {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  right: 0;
  border-radius: 50%;
}

.SideBarList.active::before {
  top: -22px;
  box-shadow: 5px 5px 2px var(--active-shadow-color);
}

.SideBarList.active::after {
  bottom: -22px;
  box-shadow: 5px -5px 2px var(--active-shadow-color);
}

.SideBarList.active {
  border-radius: 20px 0 0 20px;
  background: var(--active-background);
  width: 100%;
}

.SideBarList.active .SideBarIcons {
  color: white;
}

.SideBarIcons {
  display: flex;
  align-items: center;
  gap: 15px;
  transition:
    gap 0.25s linear,
    padding-left 0.25s linear;
  padding-left: 10px;
  white-space: pre;
}

.SideBarIcons.opened {
  gap: 30px;
  padding-left: 15px;
}
