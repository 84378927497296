.Form {
  width: 500px;
  height: 500px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.167);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
}

.Title {
  color: rgb(0, 0, 0);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px black;
  -webkit-background-clip: text;
  font-size: 70px;
  margin-bottom: 40px;
}

.ErrorMessage {
  color: red;
  margin-top: 20px;
}

.RememberMe {
  width: 80%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
