.Link {
  position: fixed;
  display: inline-block;
  right: 50px;
  bottom: 50px;
  outline: none;
  border: none;
  z-index: 10;
  color: #fff;
  font-size: 12px;
  padding: 10px 27px;
  background: #6259ca;
  border-radius: 3px;
  text-align: center;
  min-width: 80px;
  min-height: 35px;
  height: fit-content;
  cursor: pointer;
}
