.Navbar {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 30px;
}

.LeftSide {
  display: flex;
  gap: 20px;
  align-items: center;
}

.Title {
  color: #495584;
  font-size: 25px;
}

.NavbarRightSide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.Avatar {
  cursor: pointer;
}

.Search {
  min-width: 250px;
  border: 1px solid #eaedf2;
  height: 38px;
  border-radius: 50px;
  outline-color: #2f3241;
  padding-left: 20px;
}
