.AddStaff {
  height: 100%;
  display: flex;
  gap: 30px;
  /*overflow-x: scroll;*/
  padding: 0 6px;
  position: relative;
}

.StaffName {
  width: 100%;
  height: 33px;
  padding: 7px;
  border-radius: 2px;
  border: 0.5px solid #495584;
  color: #495584;
  font-size: 12px;
  resize: none;
  outline: none;
  margin-top: 9px;
}

.ButtonsParent {
  height: 33px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  gap: 5px;
}
