.AddTask {
  height: 100%;
  display: flex;
  gap: 30px;
  padding: 0 6px;
  position: relative;
}

.ScrollableBox {
  overflow-y: scroll;
  height: 100%;
}


.StartAndDeadlineParent {
  display: flex;
  gap: 16px;
}

.WorkingTimeParent {
  width: 130px;
  border-bottom: 1px solid #eaedf1;
  padding-bottom: 7px;
}

.WorkingTimeTitle {
  color: #495584;
  font-size: 8px;
}

.StartAndDeadlineDate {
  color: #212529;
  font-size: 12px;
  padding: 0;
}

.Line {
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  margin-top: 8px;
  margin-bottom: 12px;
}

.ProjectTitle {
  width: 100%;
  height: 33px;
  padding: 7px;
  border-radius: 2px;
  border: 0.5px solid #495584;
  color: #495584;
  font-size: 12px;
  resize: none;
  outline: none;
}

.ProjectDescription {
  width: 100%;
  height: 150px;
  padding: 7px;
  border-radius: 2px;
  border: 0.5px solid #495584;
  color: #495584;
  font-size: 12px;
  resize: none;
  outline: none;
}

.ButtonsParent {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.ErrorMessage {
  color: red;
  margin: 5px 0 0;
  font: 12px sans-serif;
}

.UsersParent {
  display: flex;
  align-items: center;
  padding: 0 9px;
  justify-content: space-between;
  margin-top: 20px;
}

.UsersTitle {
  color: #212529;
  font-size: 12px;
}

.CreatedUsers {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 9px;
  margin-left: 9px;
}

.UserNameFirstLatterOrImage {
  width: 30px;
  height: 30px;
  border: 0.5px solid #0e9fb3;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: #0e9fb3;
  font-size: 12px;
  overflow: hidden;
}

.Avatar {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.UserNameAndDeveloperType {
  color: #495584;
  font-size: 8px;
}

.ImagesParent {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  max-height: 150px;
  overflow-y: scroll;
  row-gap: 5px;
}

.SelectedImagesParent {
  font-size: 12px;
  padding: 10px 5px;
  border-radius: 3px;
  border: 1px solid #6259ca;
  background: #fff;
  position: relative;
}

.ImageName {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Image {
  width: 60px;
}

.RemoveFile {
  color: #6259ca;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}

.UsersList {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  max-height: 150px;
  overflow-y: scroll;
  row-gap: 5px;
}
