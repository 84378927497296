.NotificationsBox {
  width: 306px;
  height: 0vh;
  position: absolute;
  right: 34px;
  top: 104px;
  border-radius: 11px;
  background: #FFF;
  box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.25);
  transition: .5s;
  padding: 0 11px;
  overflow: hidden;
  z-index: 10;
}

.NotificationsBoxActive {
  width: 306px;
  height: 80vh;
  position: absolute;
  right: 34px;
  top: 104px;
  border-radius: 11px;
  background: #FFF;
  box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.25);
  transition: .5s;
  padding: 11px;
  overflow: hidden;
  z-index: 10;
}

.Title {
  color: #495584;
  font-size: 20px;
  font-style: normal;
}

.Line {
  width: 100%;
  height: 1px;
  background: #E6E6E6;
  margin-top: 8.5px;
  margin-bottom: 14px;
}

.Tasks {
  width: 100%;
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 7px;
  margin-bottom: 7px;
}

.TaskTitle {
  color: #495584;
  font-size: 12px;
}

.WorkingTime {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 6px;
}

.StartAndDeadline {
  color: #495584;
  font-size: 8px;
}

.ShowMoreParent {
  text-align: right;
}

.ShowMore {
  color: #495584;
  font-size: 8px;
  text-decoration-line: underline;
}
