.Link {
  color: #6259ca;
  font-size: 12px;
  padding: 0 27px;
  border-radius: 3px;
  border: 1px solid #6259ca;
  background: #fff;
  cursor: pointer;
  display: grid;
  place-items: center;
  min-width: 80px;
  min-height: 35px;
  outline: none;
  text-align: center;
}
